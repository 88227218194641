import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import {
  ArrowBack,
  BuildOutlined,
  ChatOutlined,
  Directions,
  Edit,
  ListOutlined,
  PollOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/loading/loading";
import {
  appUsersService,
  deleteAppUser,
  downloadAppUsersById,
  getAppUsersById,
  getAppUsersSummaryById,
} from "../../../services/app_users/app_users";
import { useQuery } from "react-query";
import BlockAppUser from "../components/block_app_user_popup/block_app_user";
import DeleteAppUser from "../components/block_app_user_popup/block_app_user";
import { queryClient } from "../../../providers/query/query";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { AppUsersDetailsModel } from "../model/app_users_model";
import XLSIcon from "../../../assets/xls_icon.svg";
import { downloadFromBlob } from "../../../utils/utils";
import useUser from "../../../providers/user/user_provider";
import { modify_user } from "../../../routes/routes_path";

export default function UserDetails() {
  const [blockAppUserDialog, setBlockAppUserDialog] = useState<boolean>(false);
  const [deleteAppUserDialog, setDeleteAppUserDialog] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = React.useState(0);
  const [modifiedSummaryDetails, setModifiedSummaryDetails] = useState([]);
  const { showSnackbar } = useFeedback();
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const { id } = useParams();

  const { data: appUsersDetails, isLoading } = useQuery<AppUsersDetailsModel>(
    ["appUsersDetails"],
    async () => {
      let response = await getAppUsersById({
        id: +id!,
      });
      return response?.data?.data;
    }
  );
  const { data: appUsersSummaryDetails } = useQuery(
    ["appUsersSummaryDetails"],
    async () => {
      let response = await getAppUsersSummaryById({
        id: +id!,
      });
      return response?.data?.data?.data;
    }
  );

  function addingIconAndColorToSummaryDetails() {
    let modifiedObject = appUsersSummaryDetails?.list.map(
      (item: { id: number; label: string; value: string }) => {
        if (item.label === "Needs Raised") {
          return {
            ...item,
            color: "#02b2af",
            icon: <ChatOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Jobs Listed") {
          return {
            ...item,
            color: "#2e96ff",
            icon: <ChatOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Jobs Applied") {
          return {
            ...item,
            color: "#2e96ff",
            icon: <PollOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Services Listed") {
          return {
            ...item,
            color: "#60009b",
            icon: <BuildOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Schemes Enquired") {
          return {
            ...item,
            color: "#2731c8",
            icon: <ListOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Schemes Enquired for Family") {
          return {
            ...item,
            color: "#03008d",
            icon: <ListOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Choices Completed") {
          return {
            ...item,
            color: "#02b2af",
            icon: <PollOutlined sx={{ color: "#ffffff" }} />,
          };
        } else if (item.label === "Choices Completed for Family") {
          return {
            ...item,
            color: "#2e96ff",
            icon: <PollOutlined sx={{ color: "#ffffff" }} />,
          };
        }
      }
    );
    setModifiedSummaryDetails(modifiedObject);
  }

  function downloadExcel() {
    downloadAppUsersById({ id: +id! }).then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".xlsx");
    });
  }

  function deleteAppUserApiCall(comments: string) {
    deleteAppUser({ userId: +id!, comments: comments })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("User deleted successsfully");
          queryClient.invalidateQueries({ queryKey: ["appUsersDetails"] });
        } else {
          showSnackbar("Something went wrong");
        }
        navigate(-1);
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
  }

  const updateUserAccessApiCall = (comments: string) => {
    appUsersService({
      comments: comments,
      isBlocked: !appUsersDetails?.isBlocked,
      userId: +id!,
    })
      .then((res) => {
        if (res.status === 200) {
          if (appUsersDetails?.isBlocked) {
            showSnackbar("User Unblocked successfully");
          } else {
            showSnackbar("User blocked successfully");
          }
        } else {
          showSnackbar("Something went wrong");
        }
        queryClient.invalidateQueries({ queryKey: ["appUsersDetails"] });
      })
      .catch((e) => { });
  };

  function updateUserAccess(comments: string) {
    setBlockAppUserDialog(false);
    updateUserAccessApiCall(comments);
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }
  function loading() {
    return (
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }
  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  const renderCompoBasedOnTheTab = () => {
    switch (currentTab) {
      case 0:
        return personalDetails();
      case 1:
        return locationDetails();
      case 2:
        return otherDetails();
      case 3:
        return durableDetails();
      case 4:
        return summary();
    }
  };

  useEffect(() => {
    addingIconAndColorToSummaryDetails();
  }, [appUsersSummaryDetails]);

  function summary() {
    return (
      <Box flex={1}>
        {/* <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"space-between"}
          mb={2}
        >
          <Typography>Need raised</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.needsRaised?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography>Job Listed</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.jobListed?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography>Job Applied</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.jobsApplied?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography>Service Listed</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.servicesListed?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography>Schemes Enquired</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.schemesEnquired?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography>Schemes Enquired For Family</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.schemesEnquiredForFamily?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography> Survey Completed</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.choicesCompleted?.toString()}
          </Typography>
        </Box>
        <Divider sx={{ backgroundColor: "#F5E6F5" }} />
        <Box display="flex" justifyContent={"space-between"} sx={{ my: 2 }}>
          <Typography> Survey Completed for family</Typography>
          <Typography fontWeight={"600"}>
            {appUsersSummaryDetails?.choicesCompletedForFamily?.toString()}
          </Typography>
        </Box> */}
        <Grid item md={12} lg={12} sm={12} xs={12}>
          <PieChart
            margin={{ left: -400 }}
            data
            series={[
              {
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 45,
                data: appUsersSummaryDetails?.list || [],
                innerRadius: 30,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: 0,
                endAngle: 360,
              },
            ]}
            height={200}
            width={750}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold",
              },
            }}
          />
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12} mt={4} ml={2}>
          <Grid container spacing={3}>
            {modifiedSummaryDetails?.map(
              (details: {
                id: number;
                label: string;
                value: string;
                icon: any;
                color: string;
              }) => {
                return (
                  <Grid item md={4} lg={4} sm={2} xs={2}>
                    <Box display="flex" flexDirection={"row"}>
                      <Box
                        sx={{ backgroundColor: details.color }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        px={2}
                      >
                        {details?.icon}
                      </Box>
                      <Card sx={{ minWidth: 100, py: 1 }}>
                        <Typography fontSize={12} mr={1} ml={1}>
                          {details?.label}
                        </Typography>
                        <Typography ml={1}>{details?.value}</Typography>
                      </Card>
                    </Box>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }

  function personalDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "UID",
            value: appUsersDetails?.id,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Full Name",
            value: appUsersDetails?.name,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Date of Birth",
            value: appUsersDetails?.dob,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Gender",
            value: appUsersDetails?.gender,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Marital Status  (Optional)",
            value: appUsersDetails?.maritalStatus,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Email  (Optional)",
            value: appUsersDetails?.emailId,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Mobile Numer",
            value: appUsersDetails?.mobileNumber,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}></Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Income",
            value: appUsersDetails?.familyIncome,
          })}
        </Grid>
        {/* <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Newsletter Email ID",
            value: "",
          })}
        </Grid> */}
        {appUsersDetails?.lat !== "0" && appUsersDetails?.lon !== "0" && (
          <Grid item md={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="primary.main">Location</Typography>
              <a
                target="_blank"
                href={`https://www.google.com/maps/dir/?api=1&destination=${appUsersDetails?.lat},${appUsersDetails?.lon}`}
                rel="noreferrer"
              >
                <Button size="small" variant="text" endIcon={<Directions />}>
                  Direction
                </Button>
              </a>
            </Box>
            <Box
              height={160}
              mt={1}
              sx={{ borderRadius: 4, overflow: "hidden" }}
            >
              <iframe
                title="map"
                width="100%"
                height="100%"
                frameBorder={0}
                src={`https://maps.google.com/maps?q=${appUsersDetails?.lat},${appUsersDetails?.lon}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
              />
            </Box>
          </Grid>
        )}
      </>
    );
  }
  function locationDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "House/Flat/Room No. (Optional)",
            value: appUsersDetails?.address_line1,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Building/Chawl Name/No. (Optional)",
            value: appUsersDetails?.address_line2,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Street/Road No./Name (Optional)",
            value: appUsersDetails?.address_line3,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Locality/Area",
            value: appUsersDetails?.area,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Landmark (Optional)",
            value: appUsersDetails?.landmark,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "State",
            value: appUsersDetails?.state,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "District",
            value: appUsersDetails?.district,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "PIN Code",
            value: appUsersDetails?.pincode,
          })}
        </Grid>
      </>
    );
  }

  function otherDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Educational Qualification",
            value: appUsersDetails?.education,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Working Status",
            value: appUsersDetails?.workingStatus,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Occupation",
            value: appUsersDetails?.occupation,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Type",
            value: appUsersDetails?.familyType,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Ownership of House",
            value: appUsersDetails?.houseOwnership,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Personal Income",
            value: appUsersDetails?.personalIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Income",
            value: appUsersDetails?.familyIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}></Grid>
      </>
    );
  }

  function durableDetails() {
    return (
      <Grid item md={6} lg={6} sm={12} xs={12}>
        <Typography color="primary.main">{"Durable details"}</Typography>
        {appUsersDetails?.durableOwnership != null &&
          appUsersDetails?.durableOwnership !== null ? (
          appUsersDetails?.durableOwnership?.map((durableItems) => (
            <Typography>{durableItems?.value}</Typography>
          ))
        ) : (
          <Typography>{"-"}</Typography>
        )}
      </Grid>
    );
  }

  function familyMemberCard() {
    return appUsersDetails?.familyMember?.map((familyMember) => {
      return (
        <Grid item md={12} lg={12} sm={12}>
          <ListItem
            sx={{
              backgroundColor: "#F5E6F5",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <ListItemText
              primary={familyMember?.name}
              secondary={familyMember?.gender}
              primaryTypographyProps={{ fontWeight: "600" }}
              secondaryTypographyProps={{ color: "#000000" }}
            />
          </ListItem>
          <Box sx={{ border: "1px solid #F5E6F5" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <ListItem>
                <ListItemText
                  primary="Relationship"
                  secondary={"Spouse"}
                  primaryTypographyProps={{ fontSize: 12 }}
                  secondaryTypographyProps={{
                    color: "#000000",
                    fontSize: 18,
                  }}
                />
              </ListItem>
              <ListItem sx={{ textAlign: "right" }}>
                <ListItemText
                  primary="Relationship"
                  secondary={familyMember?.gender}
                  primaryTypographyProps={{ fontSize: 12 }}
                  secondaryTypographyProps={{
                    color: "#000000",
                    fontSize: 18,
                  }}
                />
              </ListItem>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <ListItem>
                <ListItemText
                  primary={"Occupation"}
                  secondary={familyMember?.occupation}
                  primaryTypographyProps={{ fontSize: 12 }}
                  secondaryTypographyProps={{
                    color: "#000000",
                    fontSize: 18,
                  }}
                />
              </ListItem>
              <ListItem sx={{ textAlign: "right" }}>
                <ListItemText
                  primary="Mobile Number"
                  secondary={
                    familyMember?.mobileNumber
                      ? familyMember?.mobileNumber
                      : "NA"
                  }
                  primaryTypographyProps={{ fontSize: 12 }}
                  secondaryTypographyProps={{
                    color: "#000000",
                    fontSize: 18,
                  }}
                />
              </ListItem>
            </Box>
          </Box>
        </Grid>
      );
    });
  }

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: backgroundColor,
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }
  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={12} lg={8} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box width={200} display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    User Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >

                  <Button variant="text" onClick={() => navigate(`${modify_user}/${id}`)}>
                    <Edit color="primary" sx={{ height: 20, width: 20, mr: 1 }} />
                    Modify Details
                  </Button>
                </Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {false ? (
                  loading()
                ) : (
                  <>
                    <Tabs
                      value={currentTab}
                      onChange={handleChange}
                      sx={{
                        "MuiTabs-root css-112qdbf-MuiTabs-root": {
                          borderColor: "#F5E6F5",
                        },
                        mt: 2,
                      }}
                    >
                      <Tab
                        label="Personal details"
                        sx={{
                          textTransform: "capitalize",
                          color: "#1B001B",
                        }}
                      />
                      <Tab
                        label="Location details"
                        sx={{ textTransform: "capitalize", color: "#1B001B" }}
                      />
                      <Tab
                        label="Other details"
                        sx={{ textTransform: "capitalize", color: "#1B001B" }}
                      />
                      <Tab
                        label="Durable details"
                        sx={{ textTransform: "capitalize", color: "#1B001B" }}
                      />
                      <Tab
                        label="Summary"
                        sx={{ textTransform: "capitalize", color: "#1B001B" }}
                      />
                    </Tabs>
                    <Box mt={3}>
                      <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        container
                        spacing={3}
                        mt={1}
                      >
                        {renderCompoBasedOnTheTab()}
                        {currentTab === 0 &&
                          appUsersDetails?.familyMember?.length !== 0 && (
                            <Typography
                              variant="h6"
                              fontWeight={"600"}
                              mt={4}
                              ml={2}
                            >
                              Registered Family Members
                            </Typography>
                          )}
                        {currentTab === 0 && familyMemberCard()}
                        <Box mt={2} mb={2}>
                          <Divider />
                        </Box>
                        {appUsersDetails?.systemComments && (
                          <Grid item md={12} lg={12} sm={12} xs={12}>
                            {listItem({
                              title: appUsersDetails.isBlocked
                                ? "Reason for block"
                                : "Reason for unblock",
                              value: appUsersDetails?.systemComments,
                            })}
                          </Grid>
                        )}
                      </Grid>

                      <Box display="flex" alignItems="center" mt={3}>
                        <Typography>Download as:</Typography>
                        {IconComponent(XLSIcon, "#E9F0ED", () => {
                          downloadExcel();
                        })}
                      </Box>
                      <Grid container spacing={3} mt={1}>
                        <Grid item md={12} mt={3}>
                          <Box overflow="auto" flex={1}>
                            <Box display="flex" justifyContent="space-between">
                              <Button
                                variant="outlined"
                                sx={{
                                  pl: 2,
                                  pr: 2,
                                  textTransform: "capitalize",
                                  fontWeight: 600,
                                }}
                                onClick={() => navigate(-1)}
                              >
                                Back To Users
                              </Button>
                              <Box>
                                <Button
                                  disabled={!hasPermission("users", "delete")}
                                  variant="outlined"
                                  sx={{ minWidth: 150 }}
                                  onClick={() => {
                                    setDeleteAppUserDialog?.(true);
                                  }}
                                >
                                  Delete User
                                </Button>
                                <Button
                                  disabled={!hasPermission("users", "update")}
                                  variant="contained"
                                  onClick={() => {
                                    setBlockAppUserDialog(!blockAppUserDialog);
                                  }}
                                  sx={{
                                    minWidth: 150,
                                    ml: 2,
                                    bgcolor: appUsersDetails?.isBlocked
                                      ? "#1E6446"
                                      : "primary.main",
                                  }}
                                >
                                  {appUsersDetails?.isBlocked
                                    ? "Unblock user"
                                    : "Block User"}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <BlockAppUser
        open={blockAppUserDialog}
        onDialogClose={() => {
          setBlockAppUserDialog(false);
        }}
        updateUserAccess={updateUserAccess}
        title={
          appUsersDetails?.isBlocked
            ? "Are you sure you want to unblock this user?"
            : "Are you sure you want to block this user?"
        }
        submitBtnLabel={appUsersDetails?.isBlocked ? "Unblock" : "Block"}
      />

      <DeleteAppUser
        open={deleteAppUserDialog}
        onDialogClose={() => {
          setDeleteAppUserDialog(false);
        }}
        updateUserAccess={(remarks) => {
          setDeleteAppUserDialog(false);
          deleteAppUserApiCall(remarks);
        }}
        title="Are you sure you want to Delete this user?"
        submitBtnLabel={"Delete"}
      />
    </MainLayout>
  );
}
