import { NotificationsOutlined } from "@mui/icons-material";
import { Badge, Box, IconButton } from "@mui/material";
import { PropsWithChildren } from "react";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Profile from "./profile";

export default function Navbar({ }: PropsWithChildren) {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: "#FEFAFE06",
      }}
      ml={4}
    >
      <Breadcrumb />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        flex={1}
        mt={2}
      >
        <Profile />
        <IconButton>
          <Badge>
            {/* <NotificationsOutlined sx={{ color: "text.primary" }} /> */}
          </Badge>
        </IconButton>
      </Box>
      <Box width={128}></Box>
    </Box>
  );
}
