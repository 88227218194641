import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import {
  deleteAdmins,
  getAdmins,
  getRoles,
} from "../../../services/admin/admin_services";
import DeleteUser from "../components/delete_user/delete_uer";
import { AdminListModel } from "../model/admin_list";
import { AdminContext } from "../model/context_provider";
import ViewUserDetails from "./view_user_details/view_user_details";
import useUser from "../../../providers/user/user_provider";
import AccessDenied from "../../../components/access_denied/access_denied";
import { useQuery } from "react-query";
import { AdminsList } from "../model/admins_list";
import { queryClient } from "../../../providers/query/query";
import ClientListingPage from "../components/client_listing_page/client_listing_page";
import { deleteClient, getClient, updateClient } from "../../../services/client_mapping/client_mapping";
import { moduleValue } from "../role_management/role_management";

export function useMyContext() {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
}

export default function ClientMapping() {
  const [viewAdmin, setViewAdmin] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);
  const [userRoleBreadcrumb, setUserRoleBreadcrumb] =
    useState<string>("dashboard/admin");
  const [selectedAdminRoles, setSelectedAdminRoles] = useState({});
  const [adminList, setAdminList] = useState<AdminListModel[]>([]);
  const [selectedAdminDetails, setSelectedAdminDetails] =
    useState<AdminListModel>({} as AdminListModel);
  const [adminName, setAdminName] = useState("");
  const [adminEmailId, setAdminEmailId] = useState("");
  const [adminRole, setAdminRole] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);

  const [searchList, setSearchList] = useState<AdminListModel[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);

  const { hasPermission } = useUser();
  const { showSnackbar } = useFeedback();

  const { data, isLoading, isError } = useQuery(["client-List", currentTab, searchValue, page], async () => {
    let response = await getClient({
      size: 6,
      page: page + 1,
      module: moduleValue(currentTab + 1),
      keyword: searchValue
    });
    return response?.data?.data || [];
  });


  const { data: roleList } = useQuery(["role",], async () => {
    let res = await getRoles();
    return res.data.data;
  });

  const deleteAdminApiCall = () => {
    deleteClient({ clientId: selectedAdminDetails.id })
      .then((res) => {
        setDeleteAdmin(false);
        showSnackbar(res?.data?.data?.message || "");
        queryClient.invalidateQueries({ queryKey: ["client-List"] });
      })
      .catch((e) => {
        setDeleteAdmin(false);
        showSnackbar("Something Went wrong");
      });
  };



  useEffect(() => {
    if (isError) showSnackbar("Something went wrong");
  }, []);

  useEffect(() => {
    setSearchValue('')
  }, [currentTab])

  return (
    <AdminContext.Provider
      value={{
        userRoleBreadcrumb,
        setUserRoleBreadcrumb,
        viewAdmin,
        setViewAdmin,
        setDeleteAdmin,
        deleteAdmin,
        selectedAdminRoles,
        setSelectedAdminRoles,
        adminList,
        setAdminList,
        selectedAdminDetails,
        setSelectedAdminDetails,
        deleteAdminApiCall,
        adminName,
        setAdminName,
        adminRole,
        setAdminRole,
        adminEmailId,
        setAdminEmailId,
        isLoading,
        searchList: data?.list,
        setSearchList,
        searchValue,
        setSearchValue,
        roleList: roleList?.list
      }}
    >
      <MainLayout>
        {/* {hasPermission("Users", "Read") ? ( */}
        <Box>
          <ClientListingPage
            currentTab={currentTab}
            handleChange={(currentTab) => {
              setCurrentTab(currentTab);
              setPage(0)
            }}
            page={page}
            setPage={setPage}
            count={data?.total}
          />
        </Box>
        {/* ) : ( */}
        {/* <Box display="flex" flex={1} width="100%" height="100%">
            <AccessDenied />
          </Box>
        )} */}
        <DeleteUser />
        <ViewUserDetails />
      </MainLayout>
    </AdminContext.Provider>
  );
}
