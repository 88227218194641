import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { useNavigate } from "react-router-dom";
import { FamilyEnquiryLabels } from "../../family_enquiries/family_enquiry_list";
import { EnquiredSchemes } from "../../models/family_enquiry_list";
import dayjs from "dayjs";

interface DataGridInterface {
  labels: FamilyEnquiryLabels[];
  data: EnquiredSchemes[];
  count: number;
  page: number;
  setPage: (val: number) => void;
}

export default function DataGridFamilyEnquiries({
  labels,
  data,
  count,
  page,
  setPage,
}: DataGridInterface) {
  const navigate = useNavigate();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);

  };


  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

  return (
    <Box display={"flex"} sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <CardContent
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <Grid container direction="row" p={2}>
            {labels.map((item) => (
              <Grid item flex={1}>
                <Typography color="#91278F" fontSize={14} maxWidth={130}>
                  {item.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {data?.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <Typography>No Data Available</Typography>
            </Box>
          ) : (
            <Grid container direction="row" mt={3} sx={{ width: "100%" }}>
              {data?.map((item) => {
                const originalDate = dayjs(item?.enquiredOn);
                const formattedDate = originalDate?.format?.("DD MMM YYYY");
                return (
                  <Box
                    onClick={() =>
                      navigate(`/schemes/familyEnquiries/${item?.enquiryId}`, {
                        state: item,
                      })
                    }
                    display={"flex"}
                    flexDirection={"column"}
                    width="100%"
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#FAEDEA",
                      },
                    }}
                  >
                    <Divider
                      sx={{
                        display: "flex",
                        opacity: 0.5,
                        borderBottom: "1px dotted #7C797C",
                        mx: 2,
                      }}
                    />
                    <Grid
                      display="flex"
                      item
                      flex={1}
                      flexDirection={"row"}
                      my={3}
                      px={2}
                    >
                      <Typography display="flex" flex={1} fontSize={14}>
                        {item?.enquiryId}
                      </Typography>
                      <Typography display="flex" flex={1} fontSize={14}>
                        {item.name}
                      </Typography>
                      <Typography display="flex" flex={1} fontSize={14}>
                        {item.mobileNumber}
                      </Typography>
                      <Typography display="flex" flex={1} fontSize={14}>
                        {item.schemeName}
                      </Typography>
                      <Typography display="flex" flex={1} fontSize={14}>
                        {item?.memberName}
                      </Typography>
                      <Typography display="flex" flex={1} fontSize={14}>
                        {item.relationship}
                      </Typography>
                      <Box display="flex" flex={1}>
                        <Typography flex={1} fontSize={14}>
                          {formattedDate}
                        </Typography>
                        <img src={ArrowRight} alt="" height={15} width={15} />
                      </Box>
                    </Grid>
                  </Box>
                );
              })}
            </Grid>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mx: 2,
            }}
          >
            <Typography variant="body2">{customText}</Typography>
            <TablePagination
              component="div"
              labelRowsPerPage={customText}
              rowsPerPageOptions={[]}
              count={count}
              rowsPerPage={8}
              page={page}
              onPageChange={handleChangePage}
            />
          </Box>
        </CardActions>
      </Box>
    </Box>
  );
}
