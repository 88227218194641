import styled from "@emotion/styled";
import {
  Add,
  ArrowBack,
  DeleteOutline,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Tab,
  TablePagination,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RoleList } from "../../model/admin_roles";
import useUser from "../../../../providers/user/user_provider";
import { add_new_client, add_roles, modify_role, view_role } from "../../../../routes/routes_path";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

interface RoleListingParams {
  searchList: RoleList[];
  searchValue: string;
  setSearchValue: (val: string) => void;
  setViewSelectedRoleDetails: (val: boolean) => void;
  setSelectedRoleDetails: (val: RoleList) => void;
  roleList: RoleList[];
  setDeleteRole: (val: boolean) => void;
  currentTab: number;
  handleChange: (val: number) => void;
  page: number;
  setPage: (val: number) => void;
  count: number
}



export default function RoleListingPage({
  searchValue,
  searchList,
  setSearchValue,
  setViewSelectedRoleDetails,
  setSelectedRoleDetails,
  roleList,
  setDeleteRole,
  currentTab, handleChange, page, count,
  setPage,
}: RoleListingParams) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;


  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: 2,
        m: 1,
        minHeight: 550,
      }}
      elevation={1}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flex: 1,
        }}
      >
        <Box display="flex" flexDirection="column" flex={1} sx={{ pl: 1 }}>
          <Box flexDirection="row" display="flex" alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack></ArrowBack>
            </IconButton>
            <Typography variant="h6" fontWeight="600">
              Manage Roles and Access
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              mt: 2,
            }}
          >
            <Grid container spacing={3} sx={{ flex: 1 }}>
              <Grid
                display="flex"
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                xl={12}
                sx={{ flex: 1 }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  py={1}
                  pl={1}
                  width="100%"
                  sx={{ flex: 1 }}
                >
                  <Tabs
                    value={currentTab}
                    aria-label="basic tabs example"
                    onChange={(event: React.SyntheticEvent, currentTabVal: number) => handleChange(currentTabVal)}
                    sx={{
                      "MuiTabs-root css-112qdbf-MuiTabs-root": {
                        borderColor: "#F5E6F5",
                      },
                    }}
                  >
                    <Tab
                      label="All"
                      sx={{
                        textTransform: "capitalize",
                        color: "#1B001B",
                      }}
                    />
                    <Tab
                      label="Needs"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Schemes"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Choices"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Services"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Jobs"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                  </Tabs>
                  <Grid
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    container
                    mt={2}
                  >

                    <Grid md={4} sm={4} xs={4} lg={4} xl={4}>
                      <TextField
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(0)
                        }}
                        size="small"
                        fullWidth
                        sx={{
                          backgroundColor: "#F5E6F5",
                          borderRadius: 3,
                          borderColor: "transparent",
                        }}
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search sx={{ color: "text.primary" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {searchList?.length === 0 ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      flex={1}
                    >
                      <Typography>No Data Available</Typography>
                    </Box>
                  ) : (
                    <Box display={"flex"} my={3} flexDirection={"column"}>
                      {searchList?.map((item, index) => {
                        return (
                          <>
                            <Box
                              display="flex"
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              my={2}
                            >
                              <Box display="flex" flexDirection={"column"}>
                                <Box>
                                  <Typography
                                    fontWeight={"600"}
                                    sx={{ opacity: item?.isActive ? 1 : 0.5 }}
                                  >
                                    {item.role}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection={"row"}
                                  flexWrap={"wrap"}
                                >
                                  <Typography
                                    fontSize={14}
                                    color="#91278F"
                                    mr={1}
                                  >
                                    {'Access provided for: - '}
                                  </Typography>
                                  {item?.accessProvidedFor?.map((feature) => {
                                    return (
                                      <Box
                                        display="flex"
                                        flexDirection={"row"}
                                        flexWrap={"wrap"}
                                      >
                                        <Typography
                                          variant="body2"
                                          mr={1}
                                          fontWeight={600}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          {feature}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection={"row"}>
                                <IconButton
                                  onClick={() => {
                                    navigate(`${view_role}/${item.id}`);
                                  }}
                                  sx={{
                                    backgroundColor: "#E9F0ED",
                                    borderRadius: 4,
                                    color: "#1B001B",
                                    height: "fit-content",
                                    opacity: item?.isActive ? 1 : 0.4,
                                  }}
                                >
                                  <Visibility></Visibility>
                                </IconButton>
                                {/* {hasPermission("Users", "Update") && ( */}
                                <IconButton
                                  onClick={() => {
                                    navigate(`${modify_role}/${item.id}`);
                                  }}
                                  disabled={item?.accessProvidedFor?.length > 1 ? true : false}
                                  sx={{
                                    backgroundColor: "#F5E6F5",
                                    borderRadius: 4,
                                    ml: 2,
                                    color: "#1B001B",
                                    height: "fit-content",
                                    opacity: item?.isActive ? 1 : 0.4,
                                  }}
                                >
                                  <Edit></Edit>
                                </IconButton>
                                {/* )} */}
                                {/* {hasPermission("Users", "Delete") && ( */}
                                <IconButton
                                  disabled={item?.accessProvidedFor?.length > 1 ? true : false}
                                  onClick={() => {
                                    if (item?.isActive) {
                                      setDeleteRole(true);
                                      setSelectedRoleDetails?.(item);
                                    }
                                  }}
                                  sx={{
                                    backgroundColor: "#FAEDEA",
                                    borderRadius: 4,
                                    ml: 2,
                                    color: "#D14C2E",
                                    height: "fit-content",
                                    opacity: item?.isActive ? 1 : 0.4,
                                  }}
                                >
                                  <DeleteOutline></DeleteOutline>
                                </IconButton>
                                {/* )} */}
                              </Box>
                            </Box>
                            {index !== roleList.length - 1 && (
                              <Divider
                                sx={{
                                  display: "flex",
                                  backgroundColor: "#7C797C",
                                  opacity: 0.1,
                                }}
                              />
                            )}
                          </>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box display={'flex'} flexDirection={'column'} width={"100%"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              pl: 3,
              pr: 2,
            }}
          >
            <Typography variant="body2">{customText}</Typography>
            <TablePagination
              component="div"
              labelRowsPerPage={customText}
              rowsPerPageOptions={[]}
              count={count}
              rowsPerPage={8}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <Grid container>
            <Grid
              display="flex"
              width={"100%"}
              flexDirection="row"
              justifyContent="space-between"
              m={2}
            >
              <Grid md={2} sm={4} xs={4} lg={2} xl={2}>
                <Button
                  onClick={() => navigate("/reports", { replace: true })}
                  fullWidth
                  sx={{
                    border: "1px solid #91278F",
                    borderRadius: 2,
                    fontWeight: 600,
                    textTransform: "capitalize",
                    color: "#000000",
                  }}
                >
                  Back To Reports
                </Button>
              </Grid>

              <Grid md={2} sm={4} xs={4} lg={2} xl={2}>
                <Box>
                  <Button
                    onClick={() => {
                      navigate(add_roles);
                    }}
                    // disabled={!hasPermission("Users", "Create")}
                    variant="contained"
                    fullWidth
                    color="primary"
                  >
                    <Add fontSize={"inherit"} /> Add Admin Role
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
}
