import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { CloseOutlined, DeleteOutline, Done } from "@mui/icons-material";
import useUser from "../../../../providers/user/user_provider";
import { JobListingModel } from "../../model/job_listing_model";
import { JobListingLabels } from "../../job_listing/job_listing";

interface DataGridInterface {
  labels: JobListingLabels[];
  data: JobListingModel[];
  count: number;
  page: number;
  setPage: (val: number) => void;
  setSelectedUserId: (val: JobListingModel) => void;
  setCloseRejectJobsStatusDialog: (val: boolean) => void;
  setCloseDeleteJobsStatusDialog: (val: boolean) => void;
  updateServiceApiCall: ({
    statusId,
    jobId,
    comments,
  }: {
    statusId: number;
    jobId: number;
    comments: string;
  }) => void;
}

export default function DatGridJobListing({
  labels,
  data,
  page,
  setPage,
  count,
  setSelectedUserId,
  updateServiceApiCall,
  setCloseRejectJobsStatusDialog,
  setCloseDeleteJobsStatusDialog,
}: DataGridInterface) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => { };

  const customText = `Showing ${count ? startRange : 0} to ${endRange || 0} of ${count || 0} entries`;

  const actionComponent = ({
    status,
    actionOneDisable,
    actionTwoDisable,
    selectedItem,
  }: {
    status: string;
    actionOneDisable?: boolean;
    actionTwoDisable?: boolean;
    selectedItem: JobListingModel;
  }) => {
    switch (status) {
      case "Pending":
      case "Modified":
      case "Submitted":
      case "In Progress":
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            {hasPermission("jobs", "update") ?
              <>
                <IconButton
                  // disabled={!hasPermission("jobs", "update")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedUserId(selectedItem);
                    updateServiceApiCall({
                      statusId: 3,
                      jobId: +selectedItem?.id,
                      comments: "",
                    });
                  }}
                  sx={{
                    backgroundColor: "#E9F0ED",
                    borderRadius: 3,
                    color: "#1E6446",
                    opacity: 1,
                  }}
                >
                  <Done />
                </IconButton>
                {hasPermission("jobs", "update") && <IconButton
                  // disabled={!hasPermission("jobs", "update")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedUserId(selectedItem);
                    setCloseRejectJobsStatusDialog(true);
                  }}
                  sx={{
                    backgroundColor: "#FAEDEA",
                    borderRadius: 3,
                    ml: 2,
                    color: "#D14C2E",
                    opacity: 1,
                  }}
                >
                  <CloseOutlined />
                </IconButton>}
              </> :
              <></>}
          </Box>
        );
      case "Rejected":
      case "Approved":
      case "Closed":
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            {hasPermission("jobs", "update") && <IconButton
              sx={{
                backgroundColor: "#E9F0ED",
                borderRadius: 3,
                color: "#000000",
                opacity: actionOneDisable ? 0.4 : 1,
              }}
            >
              <Done />
            </IconButton>}

            {hasPermission("jobs", "delete") &&
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (status === "Approved") {
                    setSelectedUserId(selectedItem);
                    setCloseDeleteJobsStatusDialog(true);
                  }
                }}
                sx={{
                  backgroundColor: "#FAEDEA",
                  borderRadius: 3,
                  ml: 2,
                  color: "#D14C2E",
                  opacity: actionTwoDisable ? 0.4 : 1,
                }}
              >
                <DeleteOutline></DeleteOutline>
              </IconButton>}
          </Box>
        );
      default:
        break;
    }
  };

  function gridTypography({ text }: { text: string | number }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }

  function statusColorCodeBasedOnStatus({ status }: { status: string }) {
    if (
      status === "Modified" ||
      status === "Pending" ||
      status === "In Progress"
    ) {
      return "#91278F";
    } else if (status === "Approved") {
      return "#1E6446";
    } else {
      return "#D14C2E";
    }
  }

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid container direction="row" p={2}>
          {labels.map((item, index) => (
            <Grid
              item
              flex={index === 0 ? 0.5 : index === labels?.length - 1 ? 1.2 : 1}
            >
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              let statusColorCode = statusColorCodeBasedOnStatus({
                status: item?.jobStatus,
              });

              return (
                <Box
                  key={index}
                  onClick={(e) => {
                    navigate(`/jobs/jobsDetails/${item.id}`);
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    ":hover": {
                      // backgroundColor: "#FAEDEA",
                    },
                    cursor: "pointer",
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    <Box
                      display="flex"
                      flex={0.5}
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        textOverflow={"ellipsis"}
                        overflow="hidden"
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                    {gridTypography({ text: item?.jobType })}
                    {gridTypography({ text: item?.jobPosition })}
                    {gridTypography({ text: item?.company })}
                    {gridTypography({ text: item?.state })}
                    {gridTypography({ text: item?.city })}
                    {gridTypography({ text: item?.district })}
                    {gridTypography({ text: item?.workExperience })}
                    {gridTypography({ text: `+91 ${item?.jobContactNo}` })}

                    <Typography
                      display="flex"
                      flex={1}
                      fontSize={14}
                      fontWeight="600"
                      color={statusColorCode}
                    >
                      {item?.jobStatus}
                    </Typography>

                    <Box display="flex" flex={1.2} alignItems={"center"}>
                      {actionComponent({
                        status: item.jobStatus,
                        actionOneDisable:
                          item.jobStatus === "Approved" ||
                            item.jobStatus === "Rejected" ||
                            item.jobStatus === "Closed"
                            ? true
                            : false,
                        actionTwoDisable:
                          item.jobStatus === "Rejected" ||
                            item.jobStatus === "Closed"
                            ? true
                            : false,
                        selectedItem: item,
                      })}
                      <Box>
                        <img src={ArrowRight} alt="" height={15} width={15} />
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </CardActions>
    </Box>
  );
}
