import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { GridSearchIcon } from "@mui/x-data-grid";
import { MasterSchemeInterface } from "../../family_enquiries/family_enquiry_list";
import { Option } from "../../../needs/model/needs_filters";
import { useQuery } from "react-query";
import { getDistrictMasterData } from "../../../../services/master/master_service";

interface FilterEnquiriesInterface {
  openEnquiriesModal: boolean;
  setOpenEnquiriesModal: (val: boolean) => void;
  startDate: string;
  setStartDate: (value: string) => void;
  endDate: string;
  setEndDate: (value: string) => void;
  schemeKeyword: string;
  setSchemeKeyword: (value: string) => void;
  states: MasterSchemeInterface[];
  category: MasterSchemeInterface[];
  stateId: number | null;
  setStateId: (val: number | null) => void;
  categoryId: number | null;
  setCategoryId: (val: number | null) => void;
  resetFilter: () => void;
  ageFrom: number | null;
  setAgeFrom: (value: number | null) => void;
  ageTo: number | null
  setAgeTo: (value: number | null) => void;
  gender: string;
  setGender: (value: string) => void;
  genderList: MasterSchemeInterface[];
  // cities: Option[] | [];
  districtId: number | null;
  setDistrictId: (value: number | null) => void;
  govermentList: MasterSchemeInterface[];
  setGovernmentType: (value: number | null) => void;
}

export default function FilterFamilyEnquiries({
  openEnquiriesModal,
  setOpenEnquiriesModal,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  schemeKeyword,
  setSchemeKeyword,
  states,
  category,
  stateId,
  setStateId,
  categoryId,
  setCategoryId,
  resetFilter,
  genderList,
  districtId,
  setDistrictId,
  // cities,
  gender,
  setGender,
  setAgeFrom,
  setAgeTo,
  govermentList,
  setGovernmentType
}: FilterEnquiriesInterface) {
  const [_endDate, _setEndDate] = useState<string>(endDate);
  const [_startDate, _setStartDate] = useState<string>(endDate);
  const [_schemeKeyword, _setSchemeKeyword] = useState("");
  const [_stateId, _setStateId] = useState<number | null>(null);
  const [_categoryId, _setCategoryId] = useState<number | null>(null);
  const [_ageFrom, _setAgeFrom] = useState<number | null>(null);
  const [_ageTo, _setAgeTo] = useState<number | null>(null);
  const [_districtId, _setDistrictId] = useState<number | null>(null);
  const [_selectedGender, _setSelectedGender] = useState<string>("");
  const [_governmentType, _setGovernmentType] = useState<number | null>(null);

  useEffect(() => {
    if (_endDate != null && _startDate != null && _startDate > _endDate) {
      _setEndDate("");
    }
  }, [_startDate]);


  const { data: district } = useQuery(["districtList", _stateId], async () => {
    if (_stateId) {
      let response = await getDistrictMasterData(_stateId);
      return response?.data?.data?.list;
    }
  });


  return (
    <Dialog open={openEnquiriesModal}>
      <Box sx={{ minWidth: 400 }}>
        <Card sx={{ px: 2 }}>
          <CardHeader
            title="Filter Enquiries"
            titleTypographyProps={{ variant: "body1", fontWeight: 600 }}
            action={
              <IconButton onClick={() => setOpenEnquiriesModal(false)}>
                <Close sx={{ color: "#323232" }} />
              </IconButton>
            }
          >
            <Typography></Typography>
          </CardHeader>
          <CardHeader
            title="Filter By"
            titleTypographyProps={{ variant: "body2", fontWeight: 600 }}
            action={
              <Button
                variant="text"
                sx={{ p: 0 }}
                onClick={() => {
                  resetFilter();
                  _setEndDate("");
                  _setStartDate("");
                  _setAgeFrom(null);
                  _setAgeTo(null);
                  _setSelectedGender("")
                  _setStateId(null);
                  _setDistrictId(null);
                  _setGovernmentType(null)
                  setOpenEnquiriesModal(false);
                }}
              >
                Reset
              </Button>
            }
          >
            <Typography></Typography>
          </CardHeader>
          <CardContent sx={{ maxHeight: 400, overflow: "auto scroll" }}>
            <Typography fontSize={16} fontWeight="600">
              Scheme
            </Typography>
            <TextField
              value={_schemeKeyword}
              onChange={(e) => _setSchemeKeyword(e.target.value)}
              fullWidth
              variant="outlined"
              label={"Search for scheme"}
              placeholder={"Search for scheme"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <GridSearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mt: 1 }}
            />
            <Divider sx={{ mt: 2, mb: 1 }} />
            {_dropdownOption({
              title: "Category",
              placeholder: "Search for Category",
              options: category,
              selectedId: _categoryId,
              onSelect: (id) => {
                _setCategoryId(id);
              },
            })}
            {_dropdownOption({
              title: "Government Type",
              placeholder: "Search for Government Type",
              options: govermentList,
              selectedId: _governmentType,
              onSelect: (id) => {
                _setGovernmentType(id);
              },
            })}
            {_dropdownOption({
              title: "State",
              placeholder: "Search for State",
              options: states,
              selectedId: _stateId,
              onSelect: (id) => {
                _setStateId(id);
              },
            })}
            {_dropdownOption({
              title: "District",
              placeholder: "Select District",
              selectedId: _districtId,
              onSelect: (id) => {
                _setDistrictId(id);
              },
              options:
                district?.map((city: any) => {
                  return { id: city.id, value: city?.value };
                }) || [],
            })}
            {_dateRange({
              title: "Date Range",
              setEndDate: _setEndDate,
              setStartDate: _setStartDate,
              startDate: _startDate,
              endDate: _endDate,
            })}
            {/* <Box display="flex" flexDirection={"row"} mt={2}>
              <TextField
                value={_ageFrom}
                fullWidth
                sx={{ mr: 2 }}
                label="From Age"
                placeholder="set from age"
                onChange={(e) => {
                  _setAgeFrom(+e.target.value);
                }}
              />
              <TextField
                value={_ageTo}
                fullWidth
                sx={{ mr: 2 }}
                label="To Age"
                placeholder="set To age"
                onChange={(e) => {
                  _setAgeTo(+e.target.value);
                }}
              />
            </Box> */}
            {/* <Divider sx={{ mt: 2, mb: 1 }} /> */}
            <Typography mt={2} fontSize={16} fontWeight="600" mb={1}>
              Gender
            </Typography>
            <Box>
              {genderList?.map((gender) => {
                return (
                  <Button
                    onClick={() => {
                      if (_selectedGender === gender?.value) {
                        _setSelectedGender("");
                      } else {
                        _setSelectedGender(gender?.value);
                      }
                    }}
                    variant={
                      _selectedGender === gender.value
                        ? "contained"
                        : "outlined"
                    }
                    sx={{ mr: 1, mb: 1 }}
                  >
                    {gender?.value}
                  </Button>
                );
              })}
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Box onClick={() => setOpenEnquiriesModal(false)}>
                <Typography
                  variant="button"
                  color="#91278F"
                  sx={{
                    borderBottom: "1px solid #990099",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Box>
              <Button
                sx={{ minWidth: 200 }}
                variant="contained"
                onClick={() => {
                  setStartDate(_startDate);
                  setEndDate(_endDate);
                  setSchemeKeyword(_schemeKeyword);
                  setCategoryId(_categoryId);
                  setStateId(_stateId);
                  setOpenEnquiriesModal(false);
                  setAgeFrom(_ageFrom);
                  setAgeTo(_ageTo);
                  setGender(_selectedGender);
                  setDistrictId(_districtId);
                  setGovernmentType(_governmentType)
                }}
              >
                Apply Filters
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );

  function _dropdownOption({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
  }: {
    title: string;
    placeholder: string;
    options: { id: number; value: string }[];
    selectedId: number | null;
    onSelect: (id: number | null) => void;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection="row">
          <Autocomplete
            fullWidth
            // multiple
            value={options.find((option) => selectedId === option?.id)}
            onChange={(e, value) => {
              onSelect(value?.id || null);
            }}
            options={options}
            getOptionLabel={(option) => option?.value}
            // defaultValue={[{ title: "The Godfather", year: 1972 }]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={placeholder}
                placeholder={placeholder}
              />
            )}
          />
        </Box>
        <Divider />
      </Box>
    );
  }

  function _dateRange({
    title,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  }: {
    title: string;
    startDate: string;
    setStartDate: (value: string) => void;
    endDate: string;
    setEndDate: (value: string) => void;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection={"column"}>
          <DatePicker
            label="Start Date"
            format="DD-MM-YYYY"
            disableFuture
            value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
            onChange={(value: Dayjs | null) => {
              setStartDate(value?.format("YYYY-MM-DD") || "");
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
          <Box p={1}></Box>
          <DatePicker
            label="End Date"
            format="DD-MM-YYYY"
            disableFuture
            value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
            onChange={(value: Dayjs | null) => {
              setEndDate(value?.format("YYYY-MM-DD") || "");
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            minDate={dayjs(startDate, "YYYY-MM-DD")}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
        </Box>
      </Box>
    );
  }
}
