import { Add, ArrowBack, DeleteOutline } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../../layouts/main/main_layout";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { useQuery } from "react-query";
import { orange } from "../../../../theme/colors";
import { roles } from "../../role_management/role_management";
import { getArea, getBrand, getNeedsFilters } from "../../../../services/needs/needs_service";
import { NeedFilters, Option } from "../../../needs/model/needs_filters";
import { getCityMasterData, getStateMasterData } from "../../../../services/master/master_service";
import MapChoicesPopup from "../../../surveyor/components/map_choices_popup/map_choices_popup";
import { Choices } from "../../../choices/model/choices";
import dayjs from "dayjs";
import { Scheme } from "../../../schemes/models/schemes";
import MapSchemePopup from "../map_schemes_popup/map_schemes_popup";
import { ClientPermission, createRoles, getRolesDetails, updateRoles } from "../../../../services/client_mapping/client_mapping";
import { manage_roles_and_access } from "../../../../routes/routes_path";
import { RoletDetails } from "../../model/role_details";
import useUser from "../../../../providers/user/user_provider";

export interface PermissionLabel {
  id: number;
  name: string;
}

export interface SelectedRoleDetails {
  featureId: number;
  permission: number[];
}

export const access_permissions: string[] = ['create', 'update', 'delete', 'read'];

export const role_lists: string[] = [
  'Needs',
  'Schemes',
  'Choices',
  'Services',
  'Jobs'
]

export default function AddAdminRole({ modifyRole, viewRole = false }:
  { modifyRole: boolean, viewRole?: boolean }) {
  const [roleName, setRoleName] = useState("");
  const [clientEmailId, setClientEmailId] = useState("");
  const { showSnackbar } = useFeedback();
  const navigate = useNavigate();
  const [_categoryId, _setCategoryId] = useState<number | null>(null);
  const [_subCategoryId, _setSubcategoryId] = useState<number | null>(null);
  const [_brand, _setBrandId] = useState<number | null>(null);
  const [_areaId, _setAreaId] = useState<number[] | null>(null);
  const [brands, setBrands] = useState([]);
  const [areas, setAreas] = useState([]);
  const [_stateId, _setStateId] = useState<number | null>(null);
  const [_city, _setCity] = useState<number[] | null>(null);
  const [accessType, setAccessType] = useState<string[]>([])
  const [moduleAccess, setModuleAccess] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const [openSchemeModal, setOpenSchemeModal] = useState(false);
  const [selectedChoicesList, setSelectedChoicesList] = useState<
    Choices[] | []
  >([]);
  const [selectedSchemeList, setSelectedSchemesList] = useState<
    Scheme[] | []
  >([]);
  const [error, setError] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false)
  const { id } = useParams();

  const { data: roleDetails } = useQuery<RoletDetails | null>(
    ["get-roles-details", modifyRole],
    async () => {
      let response = await getRolesDetails({ roleId: +id! });
      return response.data?.data;
    }
  );

  useEffect(() => {
    if (modifyRole || viewRole) {
      setRoleName(roleDetails?.roleAssigned || '')
      setAccessType(roleDetails?.actions || []);
      _setCategoryId(+roleDetails?.category?.id! || null);
      _setAreaId(roleDetails?.area?.map((i) => i?.id) || null);

      _setSubcategoryId(+roleDetails?.subcategory?.id!)
      _setStateId(+roleDetails?.state?.id!)
      _setCity(roleDetails?.city?.map((i) => i?.id) || null)
      setSelectedSchemesList(roleDetails?.schemeDetails?.map((scheme: Scheme) => {
        return { ...scheme, schemeId: scheme?.id }
      }) || []);
      setModuleAccess(roleDetails?.module[0] || '');
      setClientEmailId(roleDetails?.clientEmail || "")
      setSelectedChoicesList(roleDetails?.choiceDetails || []);
    } else {
      let filter = roles?.filter((i) => i.moduleName?.toLowerCase() === roleDetails?.module[0]);
      setModuleAccess(filter[0]?.moduleName)
    }
    if (roleDetails?.isSuperAdmin) {
      setSuperAdmin(roleDetails?.isSuperAdmin!)
    }
  }, [roleDetails])




  function permissionBody(): ClientPermission[] {
    let actions: string[] = access_permissions.filter((item: string) =>
      accessType.includes(item))
      .map((item) => item.toLowerCase()) || []
    console.log(selectedChoicesList, 'selectedChoicesList');
    if (moduleAccess.toLowerCase() === 'needs') {
      if (_categoryId !== 1) {
        return [{
          "module": "needs",
          "actions": actions,
          "filters": {
            "category_id": [_categoryId],
            "subcategory_id": [_subCategoryId],
            "brand_id": [_brand!],
            "areas_ids": _areaId,
            "state_ids": [_stateId!],
            "city_ids": _city!
          }
        }]
      } else {
        return [
          {
            "module": "needs",
            "actions": actions,
            "filters": {
              "category_id": [_categoryId],
              "subcategory_id": [_subCategoryId],
              "areas_ids": _areaId,
              "state_ids": [_stateId!],
              "city_ids": _city!
            }
          }
        ]
      }

    } else if (moduleAccess.toLowerCase() === 'schemes') {
      return [
        {
          "module": "schemes",
          "actions": actions,
          "filters": {
            "scheme_ids": selectedSchemeList.map((i) => i.schemeId)
          }
        }
      ]
    }
    else if (moduleAccess.toLowerCase() === 'choices') {

      return [
        {
          "module": "choices",
          "actions": actions,
          "filters": {
            "choice_ids": selectedChoicesList.map((i) => i.id)
          }
        }
      ]
    } else if (moduleAccess.toLowerCase() === 'services') {
      return [
        {
          "module": "services",
          "actions": actions,
          "filters": {
            "client_email": clientEmailId
          }
        }
      ]
    }
    else {
      return [
        {
          "module": "jobs",
          "actions": actions,
          "filters": {
            "client_email": clientEmailId
          }
        }
      ]
    }
  }

  function createRolesApiCall() {

    createRoles({ name: roleName, permissions: superAdmin ? [] : permissionBody(), superAdmin: superAdmin })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar('Role added successfully')
          navigate(manage_roles_and_access)
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error?.code) {
          showSnackbar(e.response.data.error.message)
        } else {
          showSnackbar('Something went wrong!!!!')
        }
      });
  }

  function updateRolesApiCall() {
    updateRoles({ name: roleName, permissions: superAdmin ? [] : permissionBody(), superAdmin: superAdmin, roleId: +id! })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar('Role modified successfully')
          navigate(manage_roles_and_access)
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error?.code) {
          showSnackbar(e.response.data.error.message)
        } else {
          showSnackbar('Something went wrong!!!!')
        }

      });
  }

  function _dropdownOption({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
    disabled,
    onClear
  }: {
    title: string;
    placeholder: string;
    options: { id: number; value: string }[];
    selectedId: number | null;
    onSelect: (id: number | null) => void;
    disabled?: boolean;
    onClear: () => void
  }) {
    return (
      <Box mr={4}>
        <Box pt={1} pb={2} display="flex" flexDirection="row">
          <Autocomplete
            disabled={disabled}
            fullWidth
            // multiple
            value={
              selectedId == null
                ? { id: null, value: "" }
                : options.find((option) => selectedId === option?.id)
            }
            onChange={(e, value) => {
              onSelect(value?.id || null);
            }}
            options={options}
            getOptionLabel={(option) => option?.value}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={placeholder} />
            )}

          />
        </Box>
      </Box>
    );
  }



  function _dropdownOptionMultiselect({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
    disabled,
    onClear
  }: {
    title: string;
    placeholder: string;
    options: { id: number; value: string }[];
    selectedId: number[] | null;
    onSelect: (id: number[] | null) => void;
    disabled?: boolean;
    onClear: () => void
  }) {
    return (
      <Box mr={4}>
        <Box pt={1} pb={2} display="flex" flexDirection="row">
          <Autocomplete
            disabled={disabled}
            fullWidth
            multiple
            options={(options || []).map(
              (item) => item.id
            )}
            disableCloseOnSelect
            getOptionLabel={(option) =>
              (options || []).find(
                (item) => item.id === option
              )?.value || ""
            }
            value={selectedId == null
              ? []
              : selectedId}
            onChange={(_, value, reason, details) => {
              if (
                value ||
                [].find((item) => item === details?.option)
              ) {
                let d = options?.filter(
                  (item: any) => {
                    return item.isAggregate === true;
                  }
                );
                if (value?.find((itm) => itm === d?.[0]?.id)) {
                  onSelect([d?.[0]?.id]);
                } else {
                  onSelect([...value]);
                }
              }
            }}
            renderOption={(
              props,
              option,
              { selected, index },
              { value }
            ) => {
              let isAllSelected: boolean = !!value?.find(
                (v) =>
                  // (options || []).find(
                  //   (item) => item.id === v
                  // )?.isAggregate ||
                  false
              );

              let optionDetails = (
                options || []
              ).find((item) => item.id === option);

              return (
                <>
                  <li {...props}>
                    <Checkbox
                      checked={selected}
                      value={index}
                    />
                    {optionDetails?.value}
                  </li>
                  {/* {optionDetails?.isAggregate ? <Divider /> : null} */}
                </>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${title}`}
                fullWidth
                placeholder={`select ${title}`}
              // error={!!errors.personalIncome}
              // helperText={errors.personalIncome?.message}
              />
            )}
          />
        </Box>
        {/* <Box display={'flex'} flexDirection={'row'}>
          {selectedId?.map((val) => {
            return <Box
              display={'flex'} flexDirection={'row'}
              sx={{ width: 'fit-content', borderRadius: 2 }}
              px={1}
              bgcolor='primary.main'
              alignItems={'center'}
              mr={1}
            >
              <IconButton onClick={onClear}>
                <Close sx={{ width: 17, height: 17, color: '#ffffff' }} />
              </IconButton><Typography color={'#ffffff'}   >
                {options.find((option) => val === option?.id)?.value}
              </Typography>
            </Box>
          })}
        </Box> */}
      </Box>
    );
  }
  const getAreaApiCall = () => {
    getArea({ categoryId: _categoryId, subcategoryId: _subCategoryId }).then(
      (res) => {
        setAreas(res.data.data.list);
      }
    );
  };

  const getGrandsApiCall = () => {
    getBrand({ categoryId: _categoryId, subcategoryId: _subCategoryId }).then(
      (res) => {
        setBrands(res.data.data.list);
      }
    );
  };

  useEffect(() => {
    if (modifyRole || viewRole) {
      setTimeout(() => {
        _setBrandId(+roleDetails?.brand?.[0]?.id!)
      }, 1000);
    }
  }, [brands])

  function getSubCategories(): Option[] {
    return _categoryId
      ? needsCategory?.categories?.find(
        (category) => category.category._id === _categoryId
      )?.subcategory || []
      : [];
  }

  const { data: needsCategory } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();
      return data?.data?.data;
    }
  );

  const { data: stateMaster } = useQuery(["masterDataList"], async () => {
    let response = await getStateMasterData();
    return response?.data?.data?.list;
  });

  const { data: cityMaster } = useQuery(["cityList", _stateId], async () => {
    if (_stateId) {
      let response = await getCityMasterData(_stateId);
      return response?.data?.data?.list;
    }
  });

  useEffect(() => {
    if (_subCategoryId) {
      getGrandsApiCall();
      getAreaApiCall();
    }
  }, [_subCategoryId, _categoryId]);


  // const { data: schemes } = useQuery(
  //   ["schemes", schemeSearchValue, page],
  //   async () => {
  //     let payload = {
  //       page: page + 1,
  //       size: 8,
  //       keyword: schemeSearchValue,
  //     };
  //     let response = await getSchemes(payload);
  //     return response.data.data;
  //   }
  // );


  function permissionComp() {
    switch (moduleAccess) {
      case 'needs':
        return <>
          <Box display={'flex'} flexDirection={'column'} mt={6}  >
            <Typography variant="subtitle1" fontWeight={600}>
              {'Needs Type'}
            </Typography>
            <Box display={'flex'} flexDirection={'row'}>
              {needsCategory?.categories?.map((cat, index) => {
                return needsCategory?.categories.length - 1 !== index &&
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Radio
                      checked={cat.category._id === _categoryId}
                      onChange={() => {
                        if (!viewRole) {
                          _setCategoryId(cat.category._id)
                          // _setCategoryId(null);
                          _setSubcategoryId(null);
                          _setBrandId(null);
                          _setAreaId(null);
                          _setStateId(null);
                          _setCity(null);

                        }

                      }}
                    />
                    <Typography variant="subtitle1">
                      {cat.category.name}
                    </Typography>
                  </Box>
              })}


            </Box>
          </Box>
          <Box mt={1}>
            {_dropdownOption({
              title:
                _categoryId === 2
                  ? "Company"
                  : "Category",
              placeholder:
                _categoryId === 2
                  ? "Company"
                  : "Category",
              options: getSubCategories()?.map((option) => {
                return { id: option._id, value: option.name };
              }),
              selectedId: _subCategoryId,
              onSelect: (id) => {
                if (!viewRole) {
                  _setSubcategoryId(id);
                  _setBrandId(null);
                  _setAreaId(null);
                }
              },
              onClear: () => {
                _setSubcategoryId(null)
              },
            })}
          </Box>

          {_categoryId === 2 && (
            <Box mt={0}>
              {_dropdownOption({
                title: "Brand",
                placeholder: "Search for Brand",
                options:
                  brands?.map((brand: { id: number, value: string }) => {
                    return { id: brand.id, value: brand.value }
                  }) || [],
                selectedId: _brand,
                onSelect: (id) => {
                  if (!viewRole)
                    _setBrandId(id);
                },
                onClear: () => {
                  _setSubcategoryId(null)
                },
              })}
            </Box>
          )}
          <Box mt={0}>
            {_dropdownOptionMultiselect({
              title: "Area",
              placeholder: "Search for Area",
              options: areas || [],
              selectedId: _areaId,
              onSelect: (id) => {
                if (!viewRole)
                  _setAreaId(id);
              },
              onClear: () => {
                _setSubcategoryId(null)
              },
            })}
          </Box>
          <Box mt={0}>
            {_dropdownOption({
              title: "State",
              placeholder: "Select State",
              selectedId: _stateId,
              onSelect: (id) => {
                _setStateId(id);
              },
              onClear: () => {
                _setStateId(null)
              },
              options:
                stateMaster?.map((state: { id: number, value: string }) => {
                  return { id: state.id, value: state.value };
                }) || [],
            })}
          </Box>

          <Box mt={0}>
            {_dropdownOptionMultiselect({
              title: "City",
              placeholder: "Search for City",
              options: cityMaster,
              selectedId: _city,
              onSelect: (id) => {
                if (!viewRole)
                  _setCity(id);
              },
              onClear: () => { }
            })}
          </Box>
        </>;

      case 'choices':
        return <Box>
          {selectedChoicesList?.length !== 0 && !viewRole && (
            <Box
              display={"flex"}
              mt={4}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography variant="body2" fontWeight={"600"}>
                Choices Mapped
              </Typography>
              <Button
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={"600"}
                  color="primary.main"
                >
                  Map more choices
                </Typography>
              </Button>
            </Box>
          )}
          {selectedChoicesList?.length !== 0 ?
            selectedChoicesList?.map((choice: any) => {
              return <>
                <ListItem
                  sx={{
                    margin: 0,
                    padding: 0,
                    mt: 3,
                  }}
                >
                  <ListItemText
                    primary={choice?.name}
                    secondary={
                      <Box display="flex" flexDirection="row">
                        <Typography variant="body2">{`Questions:${choice.totalQuestions}`}</Typography>
                        <Typography variant="body2" ml={3}>{`Valid from: ${choice.validFrom
                          ? dayjs(choice.validFrom, "YYYY-MM-DD").format(
                            "DD-MM-YYYY"
                          )
                          : "-"
                          }`}</Typography>
                        <Typography variant="body2" ml={3}>{`Valid to: ${choice.validTo
                          ? dayjs(choice.validTo, "YYYY-MM-DD").format(
                            "DD-MM-YYYY"
                          )
                          : "-"
                          }`}</Typography>

                      </Box>
                    }
                    primaryTypographyProps={{
                      color: "#1B001B",
                      fontWeight: 600
                    }}
                    secondaryTypographyProps={{
                      color: "#91278F",
                    }}
                  />
                  <ListSubheader sx={{ margin: 0, padding: 0 }}>
                    <IconButton
                      style={{ marginLeft: 16 }}
                      onClick={() => {
                        if (!viewRole) {
                          let filteredList = selectedChoicesList.filter(
                            (item) => item?.id !== choice.id
                          );
                          setSelectedChoicesList(filteredList);
                        }
                      }}
                      sx={{
                        backgroundColor: "#E9F0ED",
                        borderRadius: 4,
                        color: "#1B001B",
                      }}
                    >
                      <DeleteOutline></DeleteOutline>
                    </IconButton>
                  </ListSubheader>
                </ListItem>
              </>
            })
            : (
              <Box
                display="flex"
                flexDirection={"column"}
                mt={3}
                borderRadius={5}
                sx={{ minHeight: 200, border: "1px dashed #91278F" }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconButton onClick={() => setOpenModal(true)}>
                  <Add sx={{ color: "#91278F" }} />
                </IconButton>
                <Button
                  onClick={() => {
                    if (!viewRole) {
                      setOpenModal(true);
                    }
                  }}
                  sx={{ color: "#1B001B", fontSize: 18 }}
                >
                  Click here to map choices
                </Button>
              </Box>
            )
          }
        </Box>
      case 'schemes':
        return <Box>
          {selectedSchemeList?.length !== 0 && (
            <Box
              display={"flex"}
              mt={4}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography variant="body2" fontWeight={"600"}>
                Schemes Mapped
              </Typography>
              <Button
                onClick={() => {
                  setOpenSchemeModal(true);
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={"600"}
                  color="primary.main"
                >
                  Map more schemes
                </Typography>
              </Button>
            </Box>
          )}
          {selectedSchemeList?.length !== 0 ? (
            selectedSchemeList?.map((item: Scheme) => {
              return (
                <>
                  <ListItem
                    sx={{
                      margin: 0,
                      padding: 0,
                      mt: 3,
                    }}
                  >
                    <ListItemText
                      primary={item?.name}
                      secondary={
                        <Box display="flex" flexDirection="row">
                          <Typography variant="body2">{item.governmentType}</Typography>
                          <Typography variant="body2">{item.schemeGovernmentType}</Typography>
                          <Box display={"flex"} flexDirection={'row'} mb={1}>
                            {item?.categories?.map((cat: string) => {
                              return (
                                <Typography
                                  color="primary.main"
                                  variant="body2"
                                  textTransform={"capitalize"}
                                  ml={1}
                                  mr={1}
                                >
                                  {cat}
                                </Typography>
                              );
                            })}
                            {item?.schemeCategory?.map((cat: string) => {
                              return (
                                <Typography
                                  color="primary.main"
                                  variant="body2"
                                  textTransform={"capitalize"}
                                  ml={1}
                                  mr={1}
                                >
                                  {cat}
                                </Typography>
                              );
                            })}
                          </Box>
                        </Box>
                      }
                      primaryTypographyProps={{
                        color: "#1B001B",
                        fontWeight: 600
                      }}
                      secondaryTypographyProps={{
                        color: "#91278F",
                      }}
                    />
                    <ListSubheader sx={{ margin: 0, padding: 0 }}>
                      <IconButton
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                          if (!viewRole) {
                            let filteredList = selectedSchemeList.filter(
                              (scheme) => item?.schemeId !== scheme?.schemeId
                            );
                            setSelectedSchemesList(filteredList);
                          }
                        }}
                        sx={{
                          backgroundColor: "#E9F0ED",
                          borderRadius: 4,
                          color: "#1B001B",
                        }}
                      >
                        <DeleteOutline></DeleteOutline>
                      </IconButton>
                    </ListSubheader>
                  </ListItem>
                </>
              );
            })
          ) : (
            <Box
              display="flex"
              flexDirection={"column"}
              mt={3}
              borderRadius={5}
              sx={{ minHeight: 200, border: "1px dashed #91278F" }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <IconButton onClick={() => setOpenSchemeModal(true)}>
                <Add sx={{ color: "#91278F" }} />
              </IconButton>
              <Button
                onClick={() => {
                  if (!viewRole) {
                    setOpenSchemeModal(true);
                  }
                }}
                sx={{ color: "#1B001B", fontSize: 18 }}
              >
                Click here to map Schemes
              </Button>
            </Box >
          )}
        </Box>

      default:
        <></>
    }
  }


  return (
    <MainLayout>
      <Grid container spacing={3} mt={3}>
        <Grid item md={12} sm={12} xs={12} lg={6} xl={6}>
          <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="column" pl={1}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    {modifyRole ? "Modify Role" : viewRole ? "View Role Details" : "Add New Role"}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    display: "flex",
                    opacity: 0.5,
                    borderBottom: "1px dotted #7C797C",
                    my: 3
                  }}
                />
                <Box mt={2}>
                  <TextField
                    required
                    value={roleName}
                    onChange={(e) => { if (!viewRole) setRoleName(e.target.value) }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="Role Name"
                    placeholder="Enter Role name"
                  // disabled={viewRole}
                  />
                </Box>
                {(moduleAccess?.toLowerCase() === 'jobs' || moduleAccess?.toLowerCase() === 'services'
                )
                  && <Box mt={2} >
                    <TextField
                      required
                      value={clientEmailId}
                      onChange={(e) => { if (!viewRole) setClientEmailId(e.target.value); setError(false) }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Client Email Id"
                      placeholder="Enter Client Email Id"
                    // disabled={viewRole}
                    />
                    {error && (
                      <FormHelperText sx={{ color: "red", m: 0 }}>
                        Please Enter valid Email
                      </FormHelperText>
                    )}
                  </Box>}

                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={2}>
                  <Checkbox checked={superAdmin} onChange={() => { if (!viewRole) setSuperAdmin(!superAdmin) }} />
                  <Typography variant="body1" fontWeight={600}>
                    Super Admin Role
                  </Typography>
                </Box>


                {!superAdmin && <Box display={'flex'} flexDirection={'row'} mt={2}>
                  <Typography variant="subtitle2">
                    Provide Access For
                  </Typography>
                  <Typography sx={{ color: orange }} variant="subtitle2">*</Typography>
                </Box>}
                {!superAdmin && <Box display={'flex'} flexDirection={'row'}>
                  {
                    role_lists.map((item) => {
                      return <Box display={'flex'} flexDirection={'row'} mr={1} alignItems={'center'}>
                        <Radio checked={moduleAccess === item.toLowerCase()} onClick={() => { if (!viewRole) setModuleAccess(item.toLocaleLowerCase()) }} />
                        <Typography variant="subtitle1">
                          {item}
                        </Typography>
                      </Box>
                    })
                  }
                </Box>}
                {!superAdmin && <> <Box display={'flex'} flexDirection={'row'} mt={4} justifyContent={'space-between'}>
                  <Typography variant="subtitle1" fontWeight={600}>
                    {'Access Type'}
                  </Typography>
                  <Box display={'flex'} flexDirection={'row'}>
                    <Box display={'flex'} flexDirection={'row'} mr={1} alignItems={'center'}>
                      <Button
                        variant={accessType?.length === access_permissions.length - 1 ? 'contained' : "outlined"}
                        onClick={() => {
                          if (!viewRole) {
                            let permissionIds: string[] = [];
                            if (accessType?.length === 0 || accessType?.length !== 3) {
                              permissionIds = access_permissions?.filter((permission: string) =>
                                permission !== 'create'
                              ) || []
                              setAccessType(permissionIds)
                            } else {
                              setAccessType(permissionIds)
                            }
                          }
                        }}>{'All'}</Button>
                    </Box>
                    {
                      access_permissions.map((item: string) => {
                        return <Box display={'flex'} flexDirection={'row'} mr={1} alignItems={'center'}>
                          <Button
                            disabled={item === 'create' ? true : false}
                            variant={accessType?.includes(item) ? 'contained' : "outlined"}
                            onClick={() => {
                              if (!viewRole) {
                                if (accessType.includes(item)) {
                                  setAccessType(accessType.filter((i) => i !== item));
                                } else {
                                  setAccessType([...accessType, item])
                                }

                              }
                            }
                            }
                          >
                            {item}</Button>
                        </Box>
                      })
                    }
                  </Box>
                </Box>
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mt: 4
                    }}
                  />
                  {permissionComp()}
                </>
                }
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  mt={5}
                >
                  <Button
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    sx={{
                      color: "#000000",
                    }}
                  >
                    {viewRole ? "Back" : "Cancel"}
                  </Button>
                  {!viewRole && <Button
                    onClick={() => {
                      if (roleName) {
                        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
                        if (!emailRegex?.test(clientEmailId) && (moduleAccess === 'jobs' || moduleAccess === 'services')) {
                          setError(true);
                        } else {
                          if (modifyRole) {
                            updateRolesApiCall()
                          } else {
                            createRolesApiCall()
                          }
                        }
                      }
                    }}
                    disabled={superAdmin ? !roleName : !roleName || !accessType.length ||
                      (moduleAccess === "jobs" || moduleAccess === 'services' ? !clientEmailId : false) ||
                      (moduleAccess === "schemes" ? !selectedSchemeList.length : false) ||
                      (moduleAccess === "choices" ? !selectedChoicesList.length : false) ||
                      (moduleAccess === "needs" ? !_categoryId || !_subCategoryId || !_areaId || !_stateId || !_city : false)
                    }
                    variant="contained"
                  >
                    {modifyRole ? "Modify Role" : "Add Role"}
                  </Button>}
                </Box>
              </Box>
            </CardContent>
          </Card>

        </Grid>
      </Grid>
      {openModal && <MapChoicesPopup
        openModel={openModal}
        setOpenModal={setOpenModal}
        setSelectedChoicesList={(item) => {
          setSelectedChoicesList(item!);
          setOpenModal(false);
        }}
        selectedChoicesList={selectedChoicesList}
      />}
      {openSchemeModal &&
        <MapSchemePopup
          openModel={openSchemeModal}
          setOpenModal={setOpenSchemeModal}
          setSelectedSchemeList={(item) => {
            setSelectedSchemesList(item!);
            setOpenSchemeModal(false);
          }}
          selectedSchemeList={selectedSchemeList}
        />}
    </MainLayout>
  );
}
